import React, {useEffect, useRef, useState} from "react";
// @material-ui/core components
// @material-ui/icons
// import People from "@material-ui/icons/People";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import classNames from "classnames";
import Footer from "../../components/Footer/Footer";
import {makeStyles} from "@material-ui/core/styles";
import styles from "../../assets/jss/material-kit-react/views/profilePage";
import {Redirect} from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import config from "../../config/config.json"

import {DataGrid} from "@mui/x-data-grid";
import Parallax from "../../components/Parallax/Parallax";
import NavPills from "../../components/NavPills/NavPills";
import FormControl from "@mui/material/FormControl";
import CustomInput from "../../components/CustomInput/CustomInput";
import Button from "../../components/CustomButtons/Button";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles(styles);
export default function TradePage(props) {
    const classes = useStyles();
    const {...rest} = props;

    const sessionToken = localStorage.getItem("session_token")
    if (!sessionToken) {
        return (<Redirect to="/login"/>)
    }

    function getColorFromRarity(rarName) {
        const textColorMap = {
            "WHITE": "#FFFFFF",
            "SET": "#00FF00",
            "MAGIC": "#4169E1",
            "UNIQUE": "#A59263",
            "CRAFTED": "#FFA800",
            "RARE": "#FFFF00",
        };

        return textColorMap[rarName.toUpperCase()] || "#FFFFFF";
    }

    function renderCellFunc(params) {
        let path = "https://d2r-member.com/image/" + params.row.image_path;
        let skinpath = "https://d2r-member.com/skin/" + params.row.skin_image_path;
        const rarityColor = getColorFromRarity(params.row.original_rarity);

        return (
            <Tooltip title={<img src={path} alt={params.row.name} />} arrow>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                        style={{
                            width: '16px',
                            height: '16px',
                            borderRadius: '50%',
                            marginRight: '4px',
                            backgroundColor: rarityColor,
                        }}
                    />
                    <img
                        style={{ maxHeight: '32px', maxWidth: '32px', marginRight: '4px', }}
                        src={skinpath}
                        alt={""}
                    />
                    {params.row.name}
                </div>
            </Tooltip>
        );
    }

    renderCellFunc.displayName = "Item Name"

    const [itemColumns] = useState([
        {
            field: "reseller_name", headerName: "Seller", flex: 4,
        },
     {
            field: "shareholder_name", headerName: "Owner", flex: 4,
        },
     {
            field: "node_name", headerName: "Node", flex: 4,
        },
        {
            field: "name", headerName: "Item", flex: 8, renderCell: renderCellFunc,
        },
        {
            field: "status", headerName: "Status", flex: 2,
        },
    ]);
    const [tradesData, setTradesData] = useState([]);
    const [newIssueComment, setNewIssueComment] = useState(""); //comment
    const [newIssueTradeID, setNewIssueTradeID] = useState(0); //trade_id
    const [newIssueItemID, setNewIssueItemID] = useState(0); //item_id

    const [loadingTrades, setLoadingTrades] = useState(true);

    //trade actions
    const [tradeActionRerouteTrade, setTradeActionRerouteTrade] = useState(false);

    const [rerouteNewGameName, setRerouteNewGameName] = useState("");
    const [rerouteNewGamePassword, setRerouteNewGamePassword] = useState("");
    const [rerouteNewGameRealm, setRerouteNewGameRealm] = useState("");

    const handleChange = () => {
    };
    async function getTrades() {
        let url = config.backend_base_url + 'rs/trade/all'
        await axios.get(url, {headers: {'session-token': sessionToken}}).then(response => {
            if (response.data != null && response.data["trades"] && response.data["trades"].length > 0) {
                setTradesData(response.data["trades"])
                //depending on the tab we load specific orders and set it as tradesData
            } else {
                setTradesData([])
            }
        }).catch(error => {
            console.log("Error ========>", error);
            toast.error("Error occured getting trades. Please refresh the page")
        })
    }

    let tradeActionCreateBugFunction = function () {
        axios.post(config.backend_base_url + "ml/issue/create", {
            "item_id": newIssueItemID,
            "comment": newIssueComment,
        }, {headers: {'session-token': sessionToken}}).then(response => {
            console.log("Success ========>", response.data);
            toast.success("Issue created!")
        }).catch(error => {
            console.log("Error ========>", error);
            toast.error("Error occured.")
        })
    }

    // let tradeActionReturnItemFunction = function () {
    //     toast.error("Returnals are disabled.")
    //     return
    //     // axios.post(config.backend_base_url + "ml/reversal/create", {
    //     //     "item_id": newIssueItemID,
    //     //     "comment": newIssueComment,
    //     // }, {headers: {'session-token': sessionToken}}).then(response => {
    //     //     console.log("Success ========>", response.data);
    //     //     getPendingReturnals()
    //     //     getTrades()
    //     //     toast.success("Please return the item to the appropriate game.")
    //     // }).catch(error => {
    //     //     console.log("Error ========>", error);
    //     //     toast.error("Error occured.")
    //     // })
    // }

    let tradeActionRerouteTradeFunction = function () {
        setTradeActionRerouteTrade(true)
    }

    let rerouteTradeAndKillRemainingDrops = function () {
        axios.post(config.backend_base_url + "ml/trade/reroute", {
                "trade_id": newIssueTradeID,
                "new_game_name": rerouteNewGameName,
                "new_game_password": rerouteNewGamePassword,
                "new_game_realm": rerouteNewGameRealm,
                "comment": newIssueComment,
        }, {headers: {'session-token': sessionToken}}).then(response => {
            console.log("Success ========>", response.data);
            toast.success("Trade has been rerouted, droppers will be killed automatically if needed, please refresh your page and be ready in the new game!")
            getTrades();
        }).catch(error => {
            console.log("Error ========>", error);
            toast.error("Error occured.")
        })
    }

    const [activeBot, setActiveBot] = useState({
        image_path: "",
        status: "",
        last_ping: ""
    });

    const tradeColumns = [
        {
            field: "reseller_name", headerName: "Seller", flex: 1,
        },
        {
            field: "value", headerName: "Value", flex: 1,
        },
        {
            field: "game_name", headerName: "Game Name", flex: 1,
        },
        {
            field: "game_password", headerName: "Game Password", flex: 1,
        },
        {
            field: "realm", headerName: "Realm", flex: 1,
        },
        {
            field: "node_name", headerName: "Node", flex: 1,
        },
        {
            field: "context", headerName: "Context", flex: 1,
        },
        {
            field: "created_at", headerName: "Created At", flex: 1,
            valueFormatter: (params) => {
                const date = new Date(params.value);
                return date.toLocaleString(undefined, {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                });
            },
        },
        {
            field: "remaining", headerName: "Remaining", flex: 1,
        },
        {
            field: "completed", headerName: "Completed", flex: 1,
        }
    ];

    const [selectedTrade, setSelectedTrade] = useState({
        trade: {
            id: 0,
            value: 0,
            game_name: "Game Name",
            game_password: "Game Password",
            realm: "realm",
            completed: 0,
        },
        items: [],
    });

    let dropperCellClick = function (params) {
        let url = config.backend_base_url + 'ml/bot/watcher/img/' + params.row.access_key
        axios.get(url, {headers: {'session-token': sessionToken}}).then(response => {
            if (response.data != null) {
                setActiveBot(response.data)
            }
        }).catch(error => {
            console.log("Error ========>", error);
            toast.error("Error occured getting bot img. Please refresh the page")
        })
    }

    let tradeCellClick = function (params, event) {
        event.defaultMuiPrevented = true;
        setNewIssueItemID(0)
        setNewIssueTradeID(params.row.id)
        let url = config.backend_base_url + 'rs/trade/detail/' + params.row.id
        axios.get(url, {headers: {'session-token': sessionToken}}).then(response => {
            if (response.data != null) {
                setSelectedTrade(response.data)
            }
        }).catch(error => {
            console.log("Error ========>", error);
            toast.error("Error occured getting trade details. Please refresh the page")
        })
    }

    let KillSelectedBots = function () {
        if (selectedBotIDs.length > 1) {
            toast.error("kill one at a time")
            return
        }
        axios.post(config.backend_base_url + "ml/droppers/kill", {
            "bot_ids": selectedBotIDs,
        }, {headers: {'session-token': sessionToken}}).then(response => {
            console.log(response)
            setSelectedBotIDs([])
            getBotStatus()
            toast.success("Success")
        }).catch(error => {
            console.log("Error ========>", error);
            toast.error("Error occured.")
        })
    }

    async function getBotStatus() {
        await axios.get(config.backend_base_url + 'bots/all', {headers: {'session-token': sessionToken}}).then(response => {
            if (response.data != null) {
                setBotStatusData(response.data)
            }
        }).catch(error => {
            console.log("Error ========>", error);
        })
    }

    const [selectedBotIDs, setSelectedBotIDs] = useState([]);
    const botStatusColumns = [
        {
            field: "node_name", headerName: "Node", flex: 1,
        },
        {
            field: "subnode", headerName: "Subnode", flex: 1,
        },
        {
            field: "last_ping_seconds_ago", headerName: "Last Ping", flex: 1,
        },
        {
            field: "status", headerName: "Status", flex: 2,
        }
    ];

    const [headerHeight, setHeaderHeight] = useState(0);
    const headerRef = useRef(null);


    useEffect(() => {
        const updateHeaderHeight = () => {
            if (headerRef.current) {
                const headerComputedStyle = window.getComputedStyle(headerRef.current);
                const headerHeight = headerRef.current.offsetHeight;
                const headerPaddingTop = parseInt(headerComputedStyle.paddingTop, 10);
                const headerPaddingBottom = parseInt(headerComputedStyle.paddingBottom, 10);

                // Calculate total height including padding
                const totalHeight = headerHeight + (headerPaddingTop + headerPaddingBottom) *2;

                setHeaderHeight(totalHeight);
            }
        };

        updateHeaderHeight(); // Initial call to set height

        const intervalId = setInterval(() => {
            updateHeaderHeight();
        }, 1000); // Run every second

        // Stop updating after 10 seconds
        setTimeout(() => {
            clearInterval(intervalId);
        }, 10000);

        window.addEventListener("resize", updateHeaderHeight); // Update height on resize

        return () => {
            clearInterval(intervalId); // Cleanup interval on unmount
            window.removeEventListener("resize", updateHeaderHeight); // Cleanup listener on unmount
        };
    }, []);

    const [botStatusData, setBotStatusData] = useState([]);

    if (loadingTrades) {
        getTrades();
        getBotStatus()
        setLoadingTrades(false)
    }

    return (
        <div>
            <Header
                color="dark"
                brand="D2RS"
                rightLinks={<HeaderLinks/>}
                fixed
                ref={headerRef}
                {...rest}
            />
            <Parallax small filter headerHeight={headerHeight}
            />

            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container} style={{width: "80vw", position: "relative", maxWidth: "80vw", left: "60%", marginLeft: "-50vw", textAlign: "center"}}>
                    
                    <NavPills
                        queryKey={"t"}
                        alignCenter
                        onChange={handleChange}
                        color="primary"
                        tabs={[
                            {
                                tabName: "trades",
                                tabButton: "Trades ("+tradesData.length+")",
                                tabContent: (
                                    <GridContainer spacing={1}>
                                        <GridItem xs={12} sm={12} md={12} xl={12}>
                                            <GridItem xs={12} sm={12} md={12} xl={12}>
                                                <FormControl id="createIssueForm" style={{width: "100%"}}>
                                                    <CustomInput
                                                     labelText="Comment"
                                                     id="comment"
                                                     value={newIssueComment}
                                                     formControlProps={{
                                                         fullWidth: true,
                                                     }}
                                                     onChange={(params) => {
                                                         setNewIssueComment(params.target.value)
                                                     }}
                                                     inputProps={{
                                                         type: "text",
                                                         multiline: true,
                                                         rows: 1,
                                                         autoComplete: "off",
                                                     }}
                                                     required
                                                    />
                                                </FormControl>
                                                <GridItem xs={12} sm={12} md={12} xl={12}>
                                                    <Button disabled={newIssueTradeID === 0}
                                                            color="primary"
                                                            fullWidth={true}
                                                            style={{width: "100%"}}
                                                            form="tradeActionRerouteForm" onClick={tradeActionRerouteTradeFunction}>
                                                        Reroute the remaining drops of this items trade (You can enter new game details after pressing this button)
                                                    </Button>
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={12} xl={12}>
                                                    <Button disabled={newIssueItemID === 0 || newIssueComment === ''}
                                                            color="primary"
                                                            fullWidth={true}
                                                            style={{width: "100%"}}
                                                            form="createIssueForm" onClick={tradeActionCreateBugFunction}>
                                                        Report as bug (for anything else)
                                                    </Button>
                                                </GridItem>
                                            </GridItem>
                                        </GridItem>
                                        {tradeActionRerouteTrade === true && <GridItem xs={12} sm={12} md={12} xl={12}>
                                            <CustomInput
                                             labelText="New Game Name"
                                             id="gameName"
                                             value={rerouteNewGameName}
                                             formControlProps={{
                                                 fullWidth: true,
                                             }}
                                             onChange={(params) => {
                                                 setRerouteNewGameName(params.target.value)
                                             }}
                                             inputProps={{
                                                 type: "text",
                                             }}
                                             required
                                            />
                                            <CustomInput
                                             labelText="New Game Password"
                                             id="gamePassword"
                                             value={rerouteNewGamePassword}
                                             formControlProps={{
                                                 fullWidth: true,
                                             }}
                                             onChange={(params) => {
                                                 setRerouteNewGamePassword(params.target.value)
                                             }}
                                             inputProps={{
                                                 type: "text",
                                             }}
                                             required
                                            />
                                            <b>Select a realm, if there seems to be connection issues, try a different realm than the current one</b>
                                            <Select
                                             id="rerouteTradeNewRealm"
                                             fullWidth={true}
                                             formControlProps={{
                                                 fullWidth: true,
                                             }}
                                             value={rerouteNewGameRealm}
                                             onChange={(params) => {
                                                 setRerouteNewGameRealm(params.target.value)
                                             }}
                                            >
                                                <MenuItem value={"europe"} selected>Europe</MenuItem>
                                                <MenuItem value={"americas"}>Americas</MenuItem>
                                                <MenuItem value={"asia"}>Asia</MenuItem>
                                            </Select>
                                            <GridItem xs={12} sm={12} md={12} xl={12}>
                                                <Button disabled={rerouteNewGameName === "" || rerouteNewGamePassword === "" || rerouteNewGameRealm === ""}
                                                        color="primary"
                                                        fullWidth={true}
                                                        form="rerouteTradeFinalize" onClick={rerouteTradeAndKillRemainingDrops}>
                                                    Reroute this trade
                                                </Button>
                                            </GridItem>
                                        </GridItem>}
                                        <GridItem xs={12} sm={12} md={6} xl={6}>
                                            <b>Trade Details</b>
                                            <div style={{height: "100%", width: "100%"}}>
                                                <DataGrid
                                                    autoHeight
                                                    getRowWidth={() => 'auto'}
                                                    columns={tradeColumns}
                                                    rows={tradesData}
                                                    pageSize={5}
                                                    rowsPerPageOptions={[5]}
                                                    onCellClick={tradeCellClick}
                                                />
                                            </div>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={6} xl={6}>
                                            <b>Trade Items</b>
                                            <div style={{height: "100%", width: "100%"}}>
                                                <DataGrid
                                                    autoHeight
                                                    getRowWidth={() => 'auto'}
                                                    columns={itemColumns}
                                                    rows={selectedTrade.items}
                                                    pageSize={10}
                                                    rowsPerPageOptions={[10]}
                                                />
                                            </div>
                                        </GridItem>
                                    </GridContainer>
                                )
                            },
                            {
                                tabName: "droppers",
                                tabButton: "Dropper Overview (" + botStatusData.length+")",
                                tabContent: (
                                    <GridContainer justify="center">
                                        <GridItem xs={12} sm={12} md={12}>
                                            <Button disabled={selectedBotIDs.length === 0} color="primary"  type="submit" style={{width: "100%"}} onClick={KillSelectedBots}>
                                                Kill Selected Bots to unlock accounts they are on and allow new instances to continue tasks
                                            </Button>
                                        </GridItem>
                                        <GridItem xs={12} sm={12} md={12}>
                                            <div style={{height: "100%", width: "100%"}}>
                                                <DataGrid
                                                    autoHeight
                                                    getRowWidth={() => 'auto'}
                                                    columns={botStatusColumns}
                                                    rows={botStatusData}
                                                    pageSize={100}
                                                    onCellClick={dropperCellClick}
                                                    rowsPerPageOptions={[10, 25, 50, 100]}
                                                    checkboxSelection
                                                    onSelectionModelChange={(params) => {
                                                        let newSelection = []
                                                        params.forEach(function (item) {
                                                            newSelection.push(item)
                                                        });
                                                        setSelectedBotIDs(newSelection)
                                                    }}
                                                />
                                            </div>
                                        </GridItem>

                                        <GridItem xs={12} sm={12} md={12} xl={12}>
                                            <img src={"https://d2r-member.com/botwatcher/" + activeBot.image_path}
                                                 style={{border: "1px solid black", width: "100%"}}
                                                 alt={activeBot.status}/>
                                        </GridItem>
                                    </GridContainer>
                                )
                            },
                        ]}
                    />
                    <br/>
                </div>
                <Footer/>
            </div>
        </div>
    );
}